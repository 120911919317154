<template>
  <page-container>
    <v-img v-if="$vuetify.breakpoint.mdAndUp" :src="require('@/assets/img/site/meghpakhi-banner.jpg')"></v-img>
    <v-img v-else :src="require('@/assets/logo-meghpakhi.png')" class="mx-10"></v-img>
    <div class="text-center" style="margin-top: 2rem">
      <div class="pa-4 text-justify">
        <span class="capital-first-letter">M</span>eghpakhi Academy of Music was set up by Goutam Ghosal
        together with his musician wife Shiuli Ghosal in the year 2000 at their residence at Mukundapur, Kolkata. Since
        its inception, Ghosal alone was at the helm of training its tutees till 2008. From 2008 onwards Shiuli Ghosal
        joined as a trainer for the children also a section of little older than them.
        <div v-if="!$vuetify.breakpoint.mdAndUp" class="text-center my-6">
          <iframe width="360" height="200" src="https://www.youtube.com/embed/1iclkWaNSAw?autoplay=0" allow="fullscreen;"
            style="border: none"></iframe>
        </div>
        The musical philosophy of this Academy in a word is that music cannot be taught basically. A Guru can only light
        up the path of his disciple as to how to sing. With this end in view, this Academy makes an effort to guide the
        students to get to know all musical notes first. Further, they are taught different vocal drills so they get to
        know their nuances in detail. Thereby the required part of their brain cells are developed leading to gradually
        being a mature musician. The Gurus here always try to impart their knowledge and experience to the students in
        keeping with the core essence of the perennial music of the Indian subcontinent so they can ultimately master the
        skill of presenting all genres of music without much difficulty.
        <div class="text-center py-6">
          <h3 class="font-weight-light font-italic">
            Meghpakhi Academy of Music does not believe in the group training system.<br />
            Each and every tutee is trained with individual attention.
          </h3>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-img :src="require('@/assets/logo-meghpakhi.png')" width="240px"
              class="mx-auto" style="filter: brightness(80%)"></v-img>
            <div class="text-center my-6">
              <iframe width="640" height="360" src="https://www.youtube.com/embed/1iclkWaNSAw?autoplay=0" allow="fullscreen;"
                style="border: none"></iframe>
            </div>
          </template>
        </div>
        Classes for juniors mainly occur on the weekends. For the seniors, classes are held all over the week. Online
        classes are also conducted for long-distance learners from Bengal, other states, and also from abroad.
        An online application form is also available <a @click.stop="scrollToForm()">here</a>.
        <br /><br />
        At the end of the year, an annual cultural programme is held in every winter in the name of “Baanbhasi Gaan”.
        Rehearsals for this are held continuously for about a half of a year with vibrant joy and cheers.
        <br /><br />
        <v-img :src="require('@/assets/img/site/about/11.jpg')" :width="$vuetify.breakpoint.mdAndUp ? '50%' : '100%'"
          style="float: left;" class="mr-6 mb-6"></v-img>
        With the idea of bettering the presentation skills of the students, a 'Performance Class' is held on a regular
        basis in the presence of Gurus. Some respected musicians and discerning music lovers are also invited to put
        forward their valued opinions. Also, the guru, being a creator himself, gives the opportunity to
        his deserving students to kick start their professional careers with new songs written and composed by himself.
        <br /><br />
        Immediately after the annual festival, 'Baanbhasi Gaan', a short outing for a few days is arranged taking along the
        academy inmates to boost their spirits of togetherness. Meghpakhi Academy of Music takes part in moves like social service too.
        <!-- A good few of its inmates dole out every month cash or kinds to their limitations through an organisation named 'Madhyam'. -->
        <br /><br />
        In fine, it may not be out of place to mention a little about the success story that a number of this Academy's
        alumni as well as the present circle of students have drawn the avid attention of many music aficionados of both
        the country and abroad.
      </div>
    </div>
    <div ref="applicationForm" class="text-center" style="margin-top: 2rem">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScguOSG0YjEGdIH_IhQY4_o_jdYKEQGve8J7HjzQxmBSekWFQ/viewform?embedded=true"
        :width="$vuetify.breakpoint.mdAndUp ? 700 : 400" :height="$vuetify.breakpoint.mdAndUp ? 1400 : 1500"
        frameborder="0" marginheight="0" marginwidth="0">Loading admission form...</iframe>
    </div>
  </page-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer')
  },
  methods: {
    scrollToForm () {
      this.$refs.applicationForm.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
</script>
